import { Box, Flex, Grid, Image, Card, CardBody, CardFooter,Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import BigInt from 'big-integer';






import { BrowserProvider, Contract, formatUnits, formatEther, parseUnits, parseEther } from 'ethers'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'


import Swal from 'sweetalert2';
import '../Styles/Home.css';
import Logo from '../Images/loadinglogo.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles

import NFTHeader from './NFTHead'
import { ethers } from 'ethers';
import topImg from '../Images/separator-top copy.svg';
import bottomImg from '../Images/separator-bottom copy.svg';








const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


const stakingContractAddress = '0x7d3366c596687ce83Ac3f8dbA5578cD86f470949'; 

const nftContractAddress = '0x7d3366c596687ce83Ac3f8dbA5578cD86f470949';




const Campaigns = () => {
  




    
    


return (


  <Box border="2px" borderRadius="30px" px="20px" pb="25px">
     <NFTHeader />
   
    
   
  </Box>
);
};
export default Campaigns;