import { Box, Flex, Grid, Image, Card, CardBody, CardFooter,Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';

import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import { ethers, formatEther } from 'ethers';

import { BrowserProvider, Contract, formatUnits, WebSocketProvider, parseUnits, parseEther } from 'ethers'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import BigNumber from 'bignumber.js';

import Beavers from '../artifacts/contracts/LionBeaver.sol/LionBeaver.json';
import Frogs from '../artifacts/contracts/LionFrog.sol/LionFrog.json';
import Pups from '../artifacts/contracts/LionPup.sol/LionPup.json';
import Rabbits from '../artifacts/contracts/LionRabbit.sol/LionRabbit.json';
import Ors from '../artifacts/contracts/LionOr.sol/LionOR.json';
import Token from '../artifacts/contracts/LionToken.sol/LionToken.json';

import Popup from 'reactjs-popup';

import Swal from 'sweetalert2';
import '../Styles/Home.css';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import bottomImg from '../Images/separator-top copy.svg';
import topImg from '../Images/separator-bottom copy.svg';
import beaver from '../Images/Beavers.png'
import frog from '../Images/Frogs.png'
import tang from '../Images/Tang.png'
import dog from '../Images/dog.png'
import rabbit from '../Images/Rabbit.png'




const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};


const beaversContractAddress = '0x6f203014e7CC864990D77302D463195795E5E151';
const frogContractAddress = '0x58dd1cBC171Ca692Ca734294d8c1A6D9411a5a0C';
const rabbitContractAddress = '0xc4BbA87aB22b034F3157188F37eDFe4A45530Ac4';
const dogContractAddress = '0xC7B6d3C2bceECe216b62ef2e85b9619bCb411D9e';
const orContractAddress = '0x70d478321B0B24cBc9f1F87C97d3FFE4D7a7a868';
const pupUri = "https://nftstorage.link/ipfs/bafybeihdx2nqwtlymm5oz6uuy6ez7klmhbcnac3qgaywfzc2kofhqigotm/"
const rabbitUri = "https://salmon-accurate-cockroach-606.mypinata.cloud/ipfs/QmQWpvaseZdwocGB5Zdx7uESG2xy8BaELTzrgRqGsbLM2z/"
const beaversUri = "https://salmon-accurate-cockroach-606.mypinata.cloud/ipfs/QmZH7ca8wuhB42kLu4w92LQpBozSGksvNqnkjS5Q4Q8Kjh/"
const frogUri = "https://salmon-accurate-cockroach-606.mypinata.cloud/ipfs/QmViCJhfZpaLJ41iFzKZAfSGzvGXVZYEgndD6KxutpbkrD/"
const orUri = "https://salmon-accurate-cockroach-606.mypinata.cloud/ipfs/QmZbTad9aaYbcJteH91raaP8HuQJUwVMgyhvjdmFqFQFaY/"




const NFTHead = () => {

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [showInfo, setShowInfo] = useState(true);





  const [toggleValue, setToggleValue] = useState('');
  const [infoValue, setInfoValue] = useState('info');
  const [showBeaverInfo, setShowBeaverInfo] = useState();
  const [showFrogInfo, setShowFrogInfo] = useState();
  const [showRabbitInfo, setShowRabbitInfo] = useState();
  const [showDogInfo, setShowDogInfo] = useState();
  const [showOrInfo, setShowOrInfo] = useState();
  const [animalValue, setAnimalValue] = useState('beaver');
  const [showBeaver, setShowBeaver] = useState(true);
  const [showFrog, setShowFrog] = useState();
  const [showRabbit, setShowRabbit] = useState();
  const [showDog, setShowDog] = useState();
  const [showOr, setShowOr] = useState();



  const [showBeaverClaimEth, setBeaverClaimEth] = useState();
  const [showBeaverClaimFire, setBeaverClaimFire] = useState();
  const [showBeaverClaimGeeks, setBeaverClaimGeeks] = useState();

  const [showBeaverTotalEth, setBeaverTotalEth] = useState();
  const [showBeaverTotalFire, setBeaverTotalFire] = useState();
  const [showBeaverTotalGeeks, setBeaverTotalGeeks] = useState();


  const [showFrogsClaimEth, setFrogsClaimEth] = useState();
  const [showFrogsTotalEth, setFrogsTotalEth] = useState();


  const [showDogClaimEth, setDogClaimEth] = useState();
  const [showDogClaimGeeks, setDogClaimGeeks] = useState();
  const [showDogTotalEth, setDogTotalEth] = useState();
  const [showDogTotalGeeks, setDogTotalGeeks] = useState();


  const [showOrClaimEth, setOrClaimEth] = useState();
  const [showOrTotalEth, setOrTotalEth] = useState();

  const [rabbitHistory, setRabbitHistoryData] = useState({ rewards: [] });

  const [showPopup, setShowPopup] = useState(false);

  const [beaverData, setBeaverData] = useState([]);
  const [frogData, setFrogData] = useState([]);
  const [rabbitData, setRabbitData] = useState({ rabbitData: [], rewards: [] });
  const [dogData, setDogData] = useState([]);

  const [orData, setOrData] = useState([]);
 
  const [WalletSearchRes, setWalletSearchRes] = useState();


 


const HandlePopUps = () => {
  setShowPopup(true)
  fetchrabbitHistoryData(address, setRabbitHistoryData);
}




const handleInfo = (value) => {
  if(value === 'info') {
    setInfoValue(value)
    setShowInfo(true)
    setShowBeaverInfo(false)
    setShowFrogInfo(false)
    setShowRabbitInfo(false)
    setShowDogInfo(false)
    setShowOrInfo(false)

  }

  if(value === 'beaver') {
    setInfoValue(value)
    setShowInfo(false)
    setShowBeaverInfo(true)
    setShowFrogInfo(false)
    setShowRabbitInfo(false)
    setShowDogInfo(false)
    setShowOrInfo(false)


    console.log('beaver')
  }


  if(value === 'frog') {
    setInfoValue(value)
    setShowInfo(false)
   
    setShowBeaverInfo(false)
    setShowFrogInfo(true)
    setShowRabbitInfo(false)
    setShowDogInfo(false)
    setShowOrInfo(false)
    console.log('frog')
  }


  if(value === 'rabbit') {
    setInfoValue(value)
    setShowInfo(false)
   
    setShowBeaverInfo(false)
    setShowFrogInfo(false)
    setShowRabbitInfo(true)
    setShowDogInfo(false)
    setShowOrInfo(false)
    
    console.log('rabbit')
  }


  if(value === 'dog') {
    setInfoValue(value)
    setShowInfo(false)
   
    setShowBeaverInfo(false)
    setShowFrogInfo(false)
    setShowRabbitInfo(false)
    setShowDogInfo(true)
    setShowOrInfo(false)


    console.log('dog')
  }


  if(value === 'or') {
    setInfoValue(value)
    setShowInfo(false)
   
    setShowBeaverInfo(false)
    setShowFrogInfo(false)
    setShowRabbitInfo(false)
    setShowDogInfo(false)
    setShowOrInfo(true)
    console.log('or')
  }

};


const handleAnimal = (value) => {
  if(value === 'beaver') {
    setAnimalValue(value)
    setShowBeaver(true)
    
    setShowFrog(false)
    setShowRabbit(false)
    setShowDog(false)
    setShowOr(false)
    fetchBeaverData()
    fetchBeaversPendingRewards()
    console.log('beaver')
  }
  if(value === 'frog') {
    setAnimalValue(value)
    fetchFrogData()
    fetchFrogsPendingRewards()
    setShowBeaver(false)
    setShowFrog(true)
    setShowRabbit(false)
    setShowDog(false)
    setShowOr(false)
    console.log('frog')
  }
  if(value === 'rabbit') {
    setAnimalValue(value)
    setShowBeaver(false)
    setShowFrog(false)
    fetchrabbitData()
    setShowRabbit(true)
    setShowDog(false)
    setShowOr(false)
    fetchrabbitHistoryData(address, setRabbitHistoryData);
    console.log('rabbit')
  }
  if(value === 'dog') {
    setAnimalValue(value)
    setShowBeaver(false)
    fetchDogsPendingRewards()
    fetchPupData()
    setShowFrog(false)
    setShowRabbit(false)
    setShowDog(true)
    setShowOr(false)
    console.log('dog')
  }
  if(value === 'or') {
    setAnimalValue(value)
    setShowBeaver(false)
    fetchOrPendingRewards()
    setShowFrog(false)
    fetchOrData()
    setShowRabbit(false)
    setShowDog(false)
    setShowOr(true)
    console.log('or')
  }

};

const [beaverShowing, setBeaverIntValue] = useState('geeks');
const [showFireBeaver, setShowFireBeaver] = useState();
const [showGeeksBeaver, setShowGeeksBeaver] = useState(true);
const [showETHBeaver, setShowETHBeaver] = useState();




const handleBeaverShowing = (value) => {
  if(value === 'geeks') {
    setBeaverIntValue(value)
    setShowGeeksBeaver(true)
    setShowFireBeaver(false)
    setShowETHBeaver(false)

  }
  if(value === 'eth') {
    setBeaverIntValue(value)
    setShowFireBeaver(false)
    setShowGeeksBeaver(false)
    setShowETHBeaver(true)

   
  }
  if(value === 'fire') {
    setBeaverIntValue(value)
    setShowFireBeaver(true)
    setShowGeeksBeaver(false)
    setShowETHBeaver(false)
   
    console.log('frog')
  }

  }
  const [dogShowing, setDogIntValue] = useState('eth');
  const [showEthDog, setShowEthDog] = useState(true);
  const [showGeeksDog, setShowGeeksDog] = useState();


const handleDogsShowing = (value) => {
  if(value === 'geeks') {
    setDogIntValue(value)
    setShowGeeksDog(true)
    setShowEthDog(false)

  }
  if(value === 'eth') {
    setDogIntValue(value)
    
    setShowGeeksDog(false)
    setShowEthDog(true)

   
  }
 

  }





  
  useEffect(() => {
    fetchBeaverData()
    fetchBeaversPendingRewards()
    fetchFrogData()
    fetchFrogsPendingRewards()
    fetchrabbitData()
    fetchDogsPendingRewards()
    fetchPupData()
    fetchOrData()
    fetchOrPendingRewards()
   


}, [address])


const fetchrabbitHistoryData = async (address, setRabbitHistoryData) => {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(rabbitContractAddress, Rabbits.abi, signer);

    try {
      console.log("Fetching Rabbit Data");

      // Fetching claim history for the wallet
      const [rewardsAddresses, rewardsAmounts] = await contract.viewClaimHistory(signer.address);

      // Process the rewardsAmounts to strings for consistency
      const rewardsAmountsStr = rewardsAmounts.map(amount => amount.toString());

      // Fetching token details for each reward address
      const rewardsPromises = rewardsAddresses.map(async (address, index) => {
        const tokenContract = new Contract(address, Token.abi, signer);
        const name = await tokenContract.name();
        const ticker = await tokenContract.symbol();
        const decimals = await tokenContract.decimals();
        const amount = parseFloat(formatUnits(rewardsAmountsStr[index], decimals)).toFixed(3);

        return {
          amount,
          name,
          ticker,
        };
      });

      // Await all promises to get the token details
      const rewards = await Promise.all(rewardsPromises);

      // Combine data into a single object
      const combinedData = { rewards };

      // Set the state with the combined data
      setRabbitHistoryData(combinedData);
    } catch (err) {
      console.error("Error fetching rabbit history data:", err.message);
      setRabbitHistoryData({ rewards: [] });
    }
  } else {
    setRabbitHistoryData({ rewards: [] });
  }
};
  





async function fetchBeaverData() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetching all beaver IDs owned by the wallet
        const beavers = await contract.walletOfOwner(signer);
        const activeBeavers = beavers.map((beaver) => beaver.toString());

        // Fetching details for each beaver ID
        const activeBeaversPromises = activeBeavers.map(async (beaverId) => {
          const beaver = await contract.nft(beaverId);

          // Ensure we use BigNumber to handle precision
          const formatValue = (value, unit) => {
            const formattedValue = parseFloat(formatEther(value.toString())).toFixed(3);
            return `${formattedValue} ${unit}`;
          };

          return {
            iD: beaver[0].toString(),
            ownerOf: beaver[1],
            GeekstotalClaimed: formatValue(beaver[2], "Geeks"),
            GeekslifetimeClaimed: formatValue(beaver[3], "Geeks"),
            GeekstimeLastClaimed: beaver[4].toString(),
            EthtotalClaimed: formatValue(beaver[5], "ETH"),
            EthlifetimeClaimed: formatValue(beaver[6], "ETH"),
            EthtimeLastClaimed: beaver[7].toString(),
            FireTotalClaimed: formatValue(beaver[8], "Fire"),
            FireLifetimeClaimed: formatValue(beaver[9], "Fire"),
            FireLastTimeClaimed: beaver[10].toString(),
            uri: beaversUri + beaver[0].toString() + ".png"
          };
        });

        // Resolving all promises to get the data
        const beaverData = await Promise.all(activeBeaversPromises);

        setBeaverData(beaverData);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setBeaverData(["No Active Votes Found..."]);
  }
}









async function fetchFrogData() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(frogContractAddress, Frogs.abi, signer);

    try {
      console.log("Fetching Frog DATA");

      // Fetching all frog IDs owned by the wallet
      const frogs = await contract.walletOfOwner(signer);
      const activeFrogs = frogs.map((frog) => frog.toString());

      // Fetching details for each frog ID
      const activeFrogsPromises = activeFrogs.map(async (frogId) => {
        const frog = await contract.nft(frogId);

        // Ensure we use BigNumber to handle precision
        const formatValue = (value, unit) => {
          const formattedValue = parseFloat(formatEther(value.toString())).toFixed(3);
          return `${formattedValue} ${unit}`;
        };

        return {
          iD: frog[0].toString(),
          ownerOf: frog[2],
          totalClaimed: formatValue(frog[3], "ETH"),
          lifetimeClaimed: formatValue(frog[4], "ETH"),
          timeLastClaimed: frog[5].toString(),
          uri: frogUri + frog[0].toString() + ".png"
        };
      });

      // Resolving all promises to get the data
      const frogData = await Promise.all(activeFrogsPromises);

      setFrogData(frogData);
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setFrogData(["No Active Frogs Found..."]);
  }
}


async function fetchrabbitData() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(rabbitContractAddress, Rabbits.abi, signer);

    try {
      if (address) {
        console.log("Fetching Rabbir DATA");

        // Fetching all rabbit IDs owned by the wallet
        const rabbits = await contract.walletOfOwner(signer);
        const activerabbits = rabbits.map((rabbit) => rabbit.toString());

        // Fetching pending rewards for the wallet
        const pendingRewards = await contract.viewPendingRewards(signer.address);
        const rewardsAmounts = pendingRewards[0].map((reward) => reward.toString());
        const rewardsAddresses = pendingRewards[1];

        // Fetching details for each rabbit ID
        const activerabbitsPromises = activerabbits.map(async (rabbitId) => {
          const rabbit = await contract.nft(rabbitId);
          return {
            id: rabbit[0].toString(),
            owner: rabbit[1],
            timeLastClaimed: rabbit[2].toString(),
            uri: rabbitUri + rabbit[0].toString() + ".png"
          };
        });

        const rabbitDetails = await Promise.all(activerabbitsPromises);

        // Fetching token details for each reward
        const rewardsPromises = rewardsAddresses.map(async (address, index) => {
          const tokenContract = new Contract(address, Token.abi, signer);
          const nameCall =  await tokenContract.name();
          const name = nameCall.toString();
          const ticker = await tokenContract.symbol();
          const decimals = await tokenContract.decimals();
          const amount = parseFloat(formatUnits(rewardsAmounts[index], decimals)).toFixed(3);
         

          return {
            amount,
            name,
            ticker,
          };
        });

        const rewards = await Promise.all(rewardsPromises);

        // Combine rabbit data with pending rewards
        const combinedData = {
          rabbitData: rabbitDetails,
          rewards,
        };

        setRabbitData(combinedData);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setRabbitData({ rabbitData: [], rewards: [] });
  }
}




async function fetchPupData() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(dogContractAddress, Pups.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetching all pup IDs owned by the wallet
        const pups = await contract.walletOfOwner(address);
        const activePups = pups.map((pup) => pup.toString());

        // Function to format values with unit
        const formatValue = (value, unit) => {
          const formattedValue = parseFloat(formatEther(value.toString())).toFixed(3);
          return `${formattedValue} ${unit}`;
        };

        // Fetching details for each pup ID
        const activePupsPromises = activePups.map(async (pupId) => {
          const pup = await contract.nft(pupId);
          return {
            iD: pup[0].toString(),
            ownerOf: pup[1],
            GeekstotalClaimed: formatValue(pup[2], "Geeks"),
            GeekslifetimeClaimed: formatValue(pup[3], "Geeks"),
            GeekstimeLastClaimed: pup[4].toString(),
            EthtotalClaimed: formatValue(pup[5], "ETH"),
            EthlifetimeClaimed: formatValue(pup[6], "ETH"),
            EthtimeLastClaimed: pup[7].toString(),
            uri: pupUri + pup[0].toString() + ".png"
          };
        });

        // Resolving all promises to get the data
        const pupData = await Promise.all(activePupsPromises);

        setDogData(pupData);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setDogData(["No Active NFTs Found..."]);
  }
}


async function fetchOrData() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(orContractAddress, Ors.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetching all or IDs owned by the wallet
        const ors = await contract.walletOfOwner(address);
        const activeOrs = ors.map((or) => or.toString());

        // Fetching details for each or ID
        const activeOrsPromises = activeOrs.map(async (orId) => {
          const or = await contract.nft(orId);
          const expiresUnix = parseInt(or[4].toString(), 10); // Convert to integer
          const geeksTimeLastClaimed = new Date(expiresUnix * 1000).toLocaleString(); // Convert to local time

          return {
            iD: or[0].toString(),
            ownerOf: or[1],
            GeekstotalClaimed: formatEther(or[2].toString()),
            GeekslifetimeClaimed: formatEther(or[3].toString()),
            GeeksTimeLastClaimed: geeksTimeLastClaimed, // Use the converted local time
            uri: orUri + or[0].toString() + ".png"
          };
        });

        // Resolving all promises to get the data
        const orData = await Promise.all(activeOrsPromises);

        setOrData(orData);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setOrData(["No Active NFTs Found..."]);
  }
}



async function fetchBeaversPendingRewards() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(beaversContractAddress, Beavers.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetching rewards and total earned values
        const ETH = await contract.viewPendingETHRewards(address);
        const Fire = await contract.viewPendingFireRewards(address); // Updated to Fire
        const Geeks = await contract.viewPendingGeeksRewards(address); // Updated to Geeks

        const totalETH = await contract.totalEthEarned(address);
        const totalFire = await contract.totalFireEarned(address);
        const totalGeeks = await contract.totalGeeksEarned(address);

        // Function to format values with units
        const formatValue = (value, unit) => {
          const formattedValue = parseFloat(formatEther(value.toString())).toFixed(3);
          return `${formattedValue} ${unit}`;
        };

        // Format and set rewards
        const finalEth = formatValue(ETH, "ETH");
        const finalFire = formatValue(Fire, "Fire");
        const finalGeeks = formatValue(Geeks, "Geeks");

        // Format and set totals
        const formattedTotalEth = formatValue(totalETH, "ETH");
        const formattedTotalFire = formatValue(totalFire, "Fire");
        const formattedTotalGeeks = formatValue(totalGeeks, "Geeks");

        setBeaverClaimEth(finalEth);
        setBeaverClaimFire(finalFire);
        setBeaverClaimGeeks(finalGeeks);
        setBeaverTotalEth(formattedTotalEth);
        setBeaverTotalFire(formattedTotalFire);
        setBeaverTotalGeeks(formattedTotalGeeks);

      }
    } catch (err) {
      console.log(err.message);
      setBeaverClaimEth("Error");
      setBeaverClaimFire("Error");
      setBeaverClaimGeeks("Error");
      setBeaverTotalEth("Error");
      setBeaverTotalFire("Error");
      setBeaverTotalGeeks("Error");
    }
  } else {
    setBeaverClaimEth("Error");
    setBeaverClaimFire("Error");
    setBeaverClaimGeeks("Error");
    setBeaverTotalEth("Error");
    setBeaverTotalFire("Error");
    setBeaverTotalGeeks("Error");
  }
}




async function fetchFrogsPendingRewards() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(frogContractAddress, Frogs.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetching pending rewards for the address
        const ETH = await contract.viewPendingRewards(address);
        const totalETH = await contract.totalEarned(address);

        // Ensure the value is formatted correctly to 3 decimal places
        const formatValue = (value, unit) => {
          const formattedValue = parseFloat(formatEther(value.toString())).toFixed(3);
          return `${formattedValue} ${unit}`;
        };

        const formatEth = formatValue(ETH, "ETH");
        const formatTotalEth = formatValue(totalETH, "ETH");

        setFrogsClaimEth(formatEth);
        setFrogsTotalEth(formatTotalEth);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setFrogsClaimEth("Error");
  }
}


async function fetchDogsPendingRewards() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(dogContractAddress, Pups.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetching pending rewards
        const ETH = await contract.viewPendingETHRewards(address);
        const Geeks = await contract.viewPendingGeeksRewards(address);
        const totalGeeks = await contract.totalGeeksEarned(address);
        const totalETH = await contract.totalEthEarned(address);

        // Function to format values with unit
        const formatValue = (value, unit) => {
          const formattedValue = parseFloat(formatEther(value.toString())).toFixed(3);
          return `${formattedValue} ${unit}`;
        };

        // Formatting ETH and Geeks values
        const formatEth = formatValue(ETH, "ETH");
        const formatGeeks = formatValue(Geeks, "Geeks");
        const formatTotalGeeks = formatValue(totalGeeks, "Geeks");
        const formatTotalEth = formatValue(totalETH, "ETH");

        // Setting the formatted values
        setDogClaimEth(formatEth);
        setDogClaimGeeks(formatGeeks);
        setDogTotalEth(formatTotalEth);
        setDogTotalGeeks(formatTotalGeeks);
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setDogClaimEth("Error");
    setDogClaimGeeks("Error");
  }
}




async function fetchOrPendingRewards() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(orContractAddress, Ors.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");

        // Fetching all or IDs owned by the wallet
        const Geeks = await contract.viewPendingRewards(address);
        const totalGeeks = await contract.totalEarned(address);
        // Ensure the values are strings
        const formatEth = formatEther(Geeks.toString()) + " Geeks";
        const formatTotalEth = formatEther(totalGeeks.toString()) + " Geeks";

        setOrClaimEth(formatEth);
        setOrTotalEth(formatTotalEth);
      
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setOrClaimEth("Error");
   
  }
}











const claimFrogRewards = async () => {
  try {
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
      const contract = new Contract(frogContractAddress, Frogs.abi, signer);
    
      const transaction = await contract.claimRewards();
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Claim transaction is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
    });
    const receipt = await transaction.wait();
    console.log('Transaction successful:', receipt);
    Swal.close(); // Close the pending alert
    Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Claimed Rewards Successful',
    });
    fetchFrogData()
    fetchFrogsPendingRewards()
  
  } catch (error) {
    console.error('Transaction failed:', error);

    Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason || error.message,
    });
}
};



const claimDogsRewards = async () => {
  try {
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
      const contract = new Contract(dogContractAddress, Pups.abi, signer);
    
      const transaction = await contract.ClaimRewards();
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Claim transaction is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
    });
    const receipt = await transaction.wait();
    console.log('Transaction successful:', receipt);
    Swal.close(); // Close the pending alert
    Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Claimed Rewards Successful',
    });
    fetchDogsPendingRewards()
    fetchPupData()
  
  } catch (error) {
    console.error('Transaction failed:', error);

    Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason || error.message,
    });
}
};




const claimOrRewards = async () => {
  try {
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
      const contract = new Contract(orContractAddress, Ors.abi, signer);
    
      const transaction = await contract.farmGeeksTokens();
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Claim transaction is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
    });
    const receipt = await transaction.wait();
    console.log('Transaction successful:', receipt);
    Swal.close(); // Close the pending alert
    Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Claimed Rewards Successful',
    });
  fetchOrData()
  fetchOrPendingRewards()
  } catch (error) {
    console.error('Transaction failed:', error);

    Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason || error.message,
    });
}
};



const claimRabbitRewards = async () => {
  try {
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
      const contract = new Contract(rabbitContractAddress, Rabbits.abi, signer);
    
      const transaction = await contract.claimRewards();
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Claim transaction is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
    });
    const receipt = await transaction.wait();
    console.log('Transaction successful:', receipt);
    Swal.close(); // Close the pending alert
    Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Claimed Rewards Successful',
    });
  fetchrabbitData()
  } catch (error) {
    console.error('Transaction failed:', error);

    Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason || error.message,
    });
}
};
























    const claimBeaverRewards = async () => {
      try {
        const ethersProvider = new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
          const contract = new Contract(beaversContractAddress, Beavers.abi, signer);
        
          const transaction = await contract.ClaimRewards();
          Swal.fire({
            icon: 'info',
            title: 'Transaction Pending',
            text: 'Claim transaction is pending. Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'Claimed Rewards Successful',
        });
      fetchBeaverData()
      fetchBeaversPendingRewards()
      } catch (error) {
        console.error('Transaction failed:', error);
  
        Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason || error.message,
        });
    }
  };
    







return (

  <Box mb="100px">
            <Box>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                  

                    <Card className='Btns'    textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    

                       <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="20px">
                        <Card className='NFTHead'   textAlign="center"   borderRadius="30px"  fontSize="30px" fontWeight="bold">

                        <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="20px">
                        
                          </Grid>

  
                        <br></br>

                        <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                      
      


       
    
  







                          <Card className='NFTHead'   textAlign="center"   borderRadius="30px"  fontSize="30px" fontWeight="bold">

                          {!address ? (
        <div className="toggle-container">
          <p className='cardtxt'>User Not Found<br/>Please connect Your Wallet To Begin</p>
        </div>
      ) : (
      <>
          <Box className='cardtxt' marginBottom="10px">
            <Text className='Heading'>Address Connected To Geeks Zoo</Text>
            <Text>{address}</Text>
          </Box>
          <br></br>
         
          <div className="toggle-container">
        
                            </div>
                        
          
                            </>
      )}

                          <div className="toggle-container">
                            <button className={`toggle-button ${infoValue === 'info' ? 'active' : ''}`} onClick={() => handleInfo('info')}>
                              What Are The Geeks Pets?

                            </button>

                           
                          </div>
                          <div className='spacer'></div>
                          <div className="toggle-container">
                            <button className={`toggle-button ${infoValue === 'beaver' ? 'active' : ''}`} onClick={() => handleInfo('beaver')}>
                              Beavers

                            </button>
                            <button className={`toggle-button ${infoValue === 'frog' ? 'active' : ''}`} onClick={() => handleInfo('frog')}>
                              Frogs

                            </button>
                            <button className={`toggle-button ${infoValue === 'rabbit' ? 'active' : ''}`} onClick={() => handleInfo('rabbit')}>
                              Rabbits

                            </button>
                            <button className={`toggle-button ${infoValue === 'dog' ? 'active' : ''}`} onClick={() => handleInfo('dog')}>
                              Dogs

                            </button>
                            <button className={`toggle-button ${infoValue === 'or' ? 'active' : ''}`} onClick={() => handleInfo('or')}>
                              Orangutans

                            </button>

                           
                          </div>
                          <br></br>



                          <div className="content-container">
  {showInfo && (
    <Text className='cardtxt'>
      Geeks Digital Pets invert the traditional pet/human relationship by envisioning a digital trading environment run by 550 animals that assist with gardening, deliveries, software development, cooking, military operations, and travel, and allow people to own these animals as pets.<br></br><br></br>
      In return for digital companionship, these animals provide their owners with the resources they acquire to help them with their food, shelter, and entertainment needs.
    </Text>
  )}
  <div className='spacer'></div>

  {showBeaverInfo && (
    <Text className='cardtxt'>
      The Beaver NFTs were inspired by Geeks favorite animal. Master Builders of the great outdoors, the Beaver NFTs collectively share 10% of all NFT sales, Ecosystem Volume, Utility Revenue, and Development Fees including Recurring Revenue from Aevum DeFi Builds.<br></br>
      With 43 members currently in The Beaver Lodge Telegram Group, Beaver NFT Owners have the inside scoop on everything in the Ecosystem and are Geeks first point of contact when seeking advice on critical decisions regarding the Ecosystem.
    </Text>
  )}
  <div className='spacer'></div>

  {showFrogInfo && (
    <Text className='cardtxt'>
      The Frog Army helps operate two of Geeks main utilities, The StakeHouse, an integrated Token Locker & On-Demand Staking Platform and The Shillitary, a Raid Service that will be integrated with premier raid bot(s) in the DeFi space.<br></br>
      This NFT Collection is given special rights to 10% of the revenue generated from both of these utilities, as well as a cut of tokens received for these services within the Ecosystem. The Shillitary will also be a web3 dApp used by the $Geeks Community and the Frog Army NFT will provide additional ranking points within the platform for community members engaging in the service.
    </Text>
  )}
  <div className='spacer'></div>

  {showRabbitInfo && (
    <Text className='cardtxt'>
      The Toll Rabbit NFTs were inspired by Tolls placed on highways where people are willing to pay a small fee for a large advantage when traveling. Due to Geeks Innovative Pairing Mechanics, the 4 tokens will have 20 unique prices, leading to a high degree of arbitrage or “Cross-Chain Travel” for Geeks Community Members.<br></br>
      Leveraging the EVM Compatibility of all Geeks Ecosystem Chains this arbitrage can be quickly taken advantage of, leading to profits for the Toll Rabbits who will tax the bridging of all ecosystem Tokens 1% and share it equally amongst the 100 NFTs.
    </Text>
  )}
  <div className='spacer'></div>

  {showDogInfo && (
    <Text className='cardtxt'>
      The Delivery Pups collectively share a 1% transaction fee on all $Geeks Token Pairs & Blockchain Token Pairs, providing this NFT Collection with Dual Rewards in the form of $Geeks and $ETH each week.<br></br>
      Due to the high degree of price variance created from having price differences for tokens both on-chain and cross-chain, the arbitrage opportunities within Geeks Ecosystem are expected to provide significant trading opportunities for investors on a daily basis leading to steady rewards for these NFT Holders. These NFTs are the most affordable option for receiving passive income from the ecosystem.
    </Text>
  )}
  <div className='spacer'></div>

  {showOrInfo && (
    <Text className='cardtxt'>
      The Gardening Orangutans were inspired by the fact that every Nation on earth has an inflationary native currency. Combining inflationary tokenomics with a decentralized mindset, you get a 50-piece NFT Collection that collectively Mints 10% of $Geeks token supply annually, allowing NFT Owners to mint their tokens on a daily basis.<br></br>
      Placing all of these NFT Owners in the same Telegram group, and providing them with Tokens they can “Ape” into, Geeks Ecosystem creates a trading environment where there will always be green candles for community members to enjoy.
    </Text>
  )}
  <div className='spacer'></div>
</div>



                          

                      

                        


                          </Card>


                      </Grid>
                     
                     </Card>
                     
                     </Grid>
                     <br></br>
                     <div class="chatenai-separator">
                      <img class="w-100" src={bottomImg} alt=""></img> 
                      </div>
                     

                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">
                    <br></br>
            
  

                    <div className="toggle-container">
                    <button className={`toggle-button ${animalValue === 'beaver' ? 'active' : ''}`} onClick={() => handleAnimal('beaver')}>
                      <img src={beaver} alt="Beaver" className="button-image" />
                      <span className="button-text">Beavers</span>
                    </button>
                            <button className={`toggle-button ${animalValue === 'frog' ? 'active' : ''}`} onClick={() => handleAnimal('frog')}>
                            <img src={frog} alt="Beaver" className="button-image" />
                      <span className="button-text">Frogs</span>
                              
                            </button>
                            <button className={`toggle-button ${animalValue === 'rabbit' ? 'active' : ''}`} onClick={() => handleAnimal('rabbit')}>
                            <img src={rabbit} alt="Beaver" className="button-image" />
                      <span className="button-text">Rabbits</span>
                              
                            </button>
                            <button className={`toggle-button ${animalValue === 'dog' ? 'active' : ''}`} onClick={() => handleAnimal('dog')}>
                            <img src={dog} alt="Beaver" className="button-image" />
                      <span className="button-text">Dogs</span>
                              
                            </button>
                            <button className={`toggle-button ${animalValue === 'or' ? 'active' : ''}`} onClick={() => handleAnimal('or')}>
                            <img src={tang} alt="Beaver" className="button-image" />
                      <span className="button-text">Orangutans</span>
                              
                            </button>
                          
                          </div>
                      <br></br>
                     

                       
                      {showBeaver && (

!address ? (
  <div className="toggle-container">
    <p className='cardtxt'>User Not Found<br/>Please connect Your Wallet To Begin</p>
  </div>
) :(
  <>
{beaverData.length === 0 ? (
  <div className='center-container'>
    <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
      <p className='cardtxt'>No Beavers Found</p>
      <div className='spacer'></div>
      <button
  className="toggle-button"
  onClick={() => {
    window.open('https://rarible.com/collection/base/0x6f203014e7CC864990D77302D463195795E5E151/items', '_blank');
  }}
>
Buy Beavers On Rarible
</button>
     
    </Card>
  </div>
) : (
  <>
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
      <Box className="card-header10">
        <Text>Claimable ETH<br />{showBeaverClaimEth}</Text>
      </Box>
      <Box className="card-header10">
        <Text>Claimable Fire<br />{showBeaverClaimFire}</Text>
      </Box>
      <Box className="card-header10">
        <Text>Claimable Geeks<br />{showBeaverClaimGeeks}</Text>
      </Box>
    </Grid>

    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
      <Box className="card-header10">
        <Text>Total ETH Claimed<br />{showBeaverTotalEth}</Text>
      </Box>
      <Box className="card-header10">
        <Text>Total Fire Claimed<br />{showBeaverTotalFire}</Text>
      </Box>
      <Box className="card-header10">
        <Text>Total Geeks Claimed<br />{showBeaverTotalGeeks}</Text>
      </Box>
    </Grid>
    
    <Box display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
      <button className="toggle-button" onClick={() => claimBeaverRewards()}>
        Claim Rewards
      </button>
    </Box>

    
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
      {beaverData.map((beaver, index) => (
        <div key={index}>
          {!address ? (
            <Box className="card-header10">Please Connect Wallet</Box>
          ) : (
            <Box
              key={index}
              className="vote-card-remove card-background"
              style={{
                backgroundImage: `url(${beaver.uri})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                padding: '20px',
                borderRadius: '8px',
              }}
            >
              <flex>
              <Box className="card-content">

                <Box className="card-header10">
                  <Text>Beaver #{beaver.iD}</Text>
                </Box>
                <div className="toggle-container">
                  <button className={`toggleNew-button ${beaverShowing === 'geeks' ? 'active' : ''}`} onClick={() => handleBeaverShowing('geeks')}>
                    <span className="button-text">Geeks</span>
                  </button>
                  <button className={`toggleNew-button ${beaverShowing === 'eth' ? 'active' : ''}`} onClick={() => handleBeaverShowing('eth')}>
                    <span className="button-text">ETH</span>
                  </button>
                  <button className={`toggleNew-button ${beaverShowing === 'fire' ? 'active' : ''}`} onClick={() => handleBeaverShowing('fire')}>
                    <span className="button-text">Fire</span>
                  </button>
                </div>
                <div className="chatenai-separator" style={{ marginTop: '15px', marginBottom: '15px' }}>
                  <img className="w-100" src={bottomImg} alt="" />
                </div>
                {showGeeksBeaver && (
             
                  <Box className="card-content10">
                    <Box className="card-header10">
                      <Text>Geeks User Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{beaver.GeekstotalClaimed}</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>Geeks Lifetime Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{beaver.GeekslifetimeClaimed}</Text>
                    </Box>
                  </Box>
                )}
                {showETHBeaver && (
                  <Box className="card-content10">
                    <Box className="card-header10">
                      <Text>Eth User Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{beaver.EthtotalClaimed}</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>Eth Lifetime Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{beaver.EthlifetimeClaimed}</Text>
                    </Box>
                  </Box>
                )}
                {showFireBeaver && (
                  <Box className="card-content10">
                    <Box className="card-header10">
                      <Text>Fire User Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{beaver.FireTotalClaimed}</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>Fire Lifetime Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{beaver.FireLifetimeClaimed}</Text>
                    </Box>
                  </Box>
                )}
              </Box>
              </flex>
            </Box>
            
          )}
        </div>
      ))}
    </Grid>
  </>
)}

  </>
   )
)}


{showFrog && (
  !address ? (
    <div className="toggle-container">
      <p className='cardtxt'>User Not Found<br/>Please connect Your Wallet To Begin</p>
    </div>
  ) :(
  frogData.length === 0 ? (
    <div className='center-container'>
      <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
        <p className='cardtxt'>No Frogs Found</p>
        <div className='spacer'></div>
        <button
  className="toggle-button"
  onClick={() => {
    window.open('https://rarible.com/collection/base/0x12DCeeAA5fFEEdb2618630F289e509906E3201f1/items', '_blank');
  }}
>
Buy Frogs On Rarible
</button>
       
      </Card>
    </div>
  ) : (
    <>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="20px">
        <Box className="card-header10">
          <Text>Claimable ETH<br />{showFrogsClaimEth}</Text>
        </Box>
      </Grid>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="20px">
        <Box className="card-header10">
          <Text>Total ETH Claimed<br />{showFrogsTotalEth}</Text>
        </Box>
      </Grid>
      <Box display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
        <button className="toggle-button" onClick={() => claimFrogRewards()}>
          Claim Rewards
        </button>
      </Box>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
        {frogData.map((frog, index) => (
          <div key={index}>
            {!address ? (
              <div className="toggle-container"></div>
            ) : (
            
                <Box
                key={index}
                  className="vote-card-remove card-background"
                  style={{
                    backgroundImage: `url(${frog.uri})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                   
                    padding: '20px', // Add some padding if needed
                    borderRadius: '8px', // Adjust border-radius if needed
                  }}
                >
                  <Box className="card-content">
                    <Box className="card-header10">
                      <Text>Frog #{frog.iD}</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>Total User Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{frog.totalClaimed}</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>Total Lifetime Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{frog.lifetimeClaimed}</Text>
                    </Box>
                  </Box>
                </Box>
            
            )}
          </div>
        ))}
      </Grid>
    </>
  ))
)}


{showRabbit && (
      !address ? (
        <div className="toggle-container">
          <p className='cardtxt'>User Not Found<br />Please connect Your Wallet To Begin</p>
        </div>
      ) : (
        <>
          {rabbitData.rabbitData.length && rabbitData.rewards.length ? (
            <>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button className="toggle-button" onClick={() => claimRabbitRewards()}>
                  Claim Rewards
                </Button>
              </Box>
            </>
          ) : null}
          {rabbitData.rabbitData.length ? (
           <Box display="flex" justifyContent="center" alignItems="center">
           <Popup
            open={showPopup}
            onClose={() => setShowPopup(false)}
            modal
            nested
            trigger={
              <Button
                className="toggle-button"
                
           
                onClick={() => HandlePopUps()}
              >
                <b>View Claim History</b>
              </Button>
            }
          >
            {close => (
              <div className="modal">
                <div className="headerMD">
                  <Box className="cardtxt" marginBottom="10px">
                    <Text className="Heading">Rabbit Claim History</Text>
                  </Box>
                  <div className="chatenai-separator"></div>
                </div>
                <Flex direction="column" align="center" p={4}>
                  {rabbitHistory.rewards && rabbitHistory.rewards.length === 0 ? (
                    <Text>No rewards found.</Text>
                  ) : (
                   
                   <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="20px">
                      {rabbitHistory.rewards.map((reward, index) => (
                        <Card key={index} className="vote-card-remove">
                          <Box className="card-header10">
                            <Text className='cardtxt'>Token:</Text>
                            <Text className='cardtxt'>{reward.name}</Text>
                            <br />
                            <Text className='cardtxt'>Reward Amount:</Text>
                            <Text className='cardtxt'>{reward.amount} </Text>
                          </Box>
                        </Card>
                      ))}
                  </Grid>
                  
                  )}
                  <Button
                     className="toggle-button"
                    onClick={() => close()}
                  >
                    <b>Close Window</b>
                  </Button>
                </Flex>
              </div>
            )}
          </Popup>
          </Box>
          ) :  (null)}

          

          {!rabbitData.rabbitData.length && !rabbitData.rewards.length ? (
            <div className='center-container'>
              <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
                <p className='cardtxt'>No Rabbits Found</p>
                <div className='spacer'></div>
                <button
  className="toggle-button"
  onClick={() => {
    window.open('https://rarible.com/collection/base/0x91fdB6D9eb6Ff5669B4d0c09f2Cf60ad3f5fE164/items', '_blank');
  }}
>
Buy Rabbits On Rarible
</button>
              </Card>
            </div>
          ) : (
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="20px">
              <Box>
                <h2>Owned NFTs</h2>
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="20px">
                  {rabbitData.rabbitData.map((rabbit, index) => (
                    <Box
                      key={index}
                      className="vote-card-remove card-background"
                      style={{
                        backgroundImage: `url(${rabbit.uri})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        padding: '20px',
                        borderRadius: '8px',
                      }}
                    >
                      <Box className="card-content">
                        <Box className="card-header10">
                          <Text>Rabbit #{rabbit.id}</Text>
                          <br />
                          <Text>Last Claimed</Text>
                          <Text>{rabbit.timeLastClaimed}</Text>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Box>

              {rabbitData.rewards.length > 0 && (
                <Box>
                  <h2>Pending Rewards</h2>
                  <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
                    {rabbitData.rewards.map((reward, index) => (
                      <Card key={index} className="vote-card-remove">
                        <Box className="card-header10">
                          <p>Reward Name:</p>
                          <Text>{reward.name}</Text>
                          <br />
                          <p>Reward Amount:</p>
                          <Text>{reward.amount}</Text>
                        </Box>
                      </Card>
                    ))}
                  </Grid>
                </Box>
              )}
            </Grid>
          )}
        </>
      )
    )}







{showDog && (
  !address ? (
    <div className="toggle-container">
      <p className='cardtxt'>User Not Found<br/>Please connect Your Wallet To Begin</p>
    </div>
  ) :(
  <>
    {dogData.length === 0 ? (
      <div className='center-container'>
        <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
          <p className='cardtxt'>No Dogs Found</p>
          <div className='spacer'></div>
          <button
  className="toggle-button"
  onClick={() => {
    window.open('https://rarible.com/collection/base/0xC7B6d3C2bceECe216b62ef2e85b9619bCb411D9e/items', '_blank');
  }}
>
Buy Dogs On Rarible
</button>
         
        </Card>
      </div>
    ) : (
      <>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="20px">
          <Box className="card-header10">
            <Text>Claimable ETH<br></br>{showDogClaimEth}</Text>
          </Box>
          <Box className="card-header10">
            <Text>Claimable Geeks<br></br>{showDogClaimGeeks}</Text>
          </Box>
        </Grid>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="20px">
          <Box className="card-header10">
            <Text>Total ETH Earned<br></br>{showDogTotalEth}</Text>
          </Box>
          <Box className="card-header10">
            <Text>Total Geeks Earned<br></br>{showDogTotalGeeks}</Text>
          </Box>
        </Grid>
        <Box display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
          <button className="toggle-button" onClick={() => claimDogsRewards()}>
            Claim Rewards
          </button>
        </Box>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
      {dogData.map((dog, index) => (
        <div key={index}>
          {!address ? (
            <div className="toggle-container">
            </div>
          ) : (
            <Box 
              key={index} 
              className="vote-card-remove card-background"
              style={{
                backgroundImage: `url(${dog.uri})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
               
                padding: '20px', // Add some padding if needed
                borderRadius: '8px', // Adjust border-radius if needed
              }}
            >
              <Box className="card-content">
                <Box className="card-header10">
                  <Text>Pup #{dog.iD}</Text>
                </Box>

                <div className="toggle-container">
                  <button className={`toggle-button ${dogShowing === 'geeks' ? 'active' : ''}`} onClick={() => handleDogsShowing('geeks')}>
                    <span className="button-text">Geeks</span>
                  </button>
                  <button className={`toggle-button ${dogShowing === 'eth' ? 'active' : ''}`} onClick={() => handleDogsShowing('eth')}>
                    <span className="button-text">ETH</span>
                  </button>
                </div>

                <div className="chatenai-separator" style={{ marginTop: '15px', marginBottom: '15px' }}>
                  <img className="w-100" src={bottomImg} alt="" />
                </div>

                <Box className="card-header10"></Box>
                <Box className="card-content10">
                  {showGeeksDog && (
                    <Box className="card-content10">
                      <Box className="card-header10">
                        <Text>Geeks User Claimed</Text>
                      </Box>
                      <Box className="card-header10">
                        <Text>{dog.GeekstotalClaimed}</Text>
                      </Box>

                      <Box className="card-header10">
                        <Text>Geeks Lifetime Claimed</Text>
                      </Box>
                      <Box className="card-header10">
                        <Text>{dog.GeekslifetimeClaimed}</Text>
                      </Box>

                     
                    </Box>
                  )}

                  {showEthDog && (
                    <Box className="card-content10">
                      <Box className="card-header10">
                        <Text>Eth User Claimed</Text>
                      </Box>
                      <Box className="card-header10">
                        <Text>{dog.EthtotalClaimed}</Text>
                      </Box>

                      <Box className="card-header10">
                        <Text>Eth Lifetime Claimed</Text>
                      </Box>
                      <Box className="card-header10">
                        <Text>{dog.EthlifetimeClaimed}</Text>
                      </Box>

                    
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </div>
      ))}
    </Grid>
      </>
    )}
  </>
  )
)}


{showOr && (
  !address ? (
    <div className="toggle-container">
      <p className='cardtxt'>User Not Found<br/>Please connect Your Wallet To Begin</p>
    </div>
  ) :(
  <>
    {orData.length !== 0 && (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="card-header10">
              <Text>Claimable Geeks<br />{showOrClaimEth}</Text>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="card-header10">
              <Text>Total Geeks Claimed<br />{showOrTotalEth}</Text>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
          <button className="toggle-button" onClick={() => claimOrRewards()}>
            Claim Rewards
          </button>
        </Box>
      </>
    )}

    {orData.length === 0 ? (
      <div className='center-container'>
        <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
          <p className='cardtxt'>No Orangutans Found</p>
          <div className='spacer'></div>
          <button
  className="toggle-button"
  onClick={() => {
    window.open('https://rarible.com/collection/base/0xeE7ba5f9C4A7c4Bb9f4724362316fA24CA6A8B95/items', '_blank');
  }}
>
Buy Orangutans On Rarible
</button>
         
        </Card>
      </div>
    ) : (
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
        {orData.map((or, index) => (
          <div key={index}>
            {address ? (
            
                <Box
                  className="vote-card-remove card-background"
                  style={{
                    backgroundImage: `url(${or.uri})`, // Use the URL from `or.uri` for the background
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    padding: '20px',
                    borderRadius: '8px',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <Box className="card-content">
                    <Box className="card-header10">
                      <Text>Orangutan #{or.iD}</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>Total User Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{or.GeekstotalClaimed}</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>Total Lifetime Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{or.GeekslifetimeClaimed}</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>Geeks Last Claimed</Text>
                    </Box>
                    <Box className="card-header10">
                      <Text>{or.GeeksTimeLastClaimed}</Text>
                    </Box>
                  </Box>
                </Box>
         
            ) : (
              <div className="toggle-container"></div>
            )}
          </div>
        ))}
      </Grid>
    )}
  </>
  )
)}



</Grid>
                        

          
                  
              
                </Card>
              </Grid> 


            </Box>
              <br></br>
              <div class="chatenai-separator">
                    <img class="w-100" src={bottomImg} alt=""></img> 
                </div>
           </Box>
           
        
    );
};
export default NFTHead;